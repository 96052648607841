import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Helmet } from 'react-helmet'

const SEO = ({title, description, keywords, image}) => (
    <StaticQuery
        query={query}
        render={({
            site: {
                siteMetadata:{
                    defaultTitle,
                    defaultDesc,
                    defaultImg,
                    url,
                    defaultKeywords
                }
            }
        })=>{
            const seo = {
                title: title || defaultTitle,
                description: description || defaultDesc,
                image: `${image ? image : url+defaultImg}`,
                keywords: keywords || defaultKeywords
             }
            return (
                <>
                   <Helmet>
                        <title>{seo.title}</title>
                        <meta name="image" content={seo.image}/>
                        <meta 
                            name="description" 
                            content={seo.description}
                        />
                        <meta name="keywords" content={seo.keywords} />
                        <meta name="robots" content="noindex, nofollow"/>
                    </Helmet>

                </>
            )
        }}
    />
)

const query = graphql`
  {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDesc: description
        defaultImg: image
        defaultKeywords: keywords
        url
      }
    }
  }
`

export default SEO
