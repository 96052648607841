import React from 'react';
import Navigation from './Navigation';


export default (props) => (
    <>
        <header >
            <h1>The Yorick Touch</h1>
            <h2>Creating magic on the Web</h2>
            <Navigation />
        </header>
    </>
)